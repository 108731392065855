let messages = {
  'ru': {
    'Loading...': 'Загрузка...',
    'citySearch': 'Поиск города',
    'choiceCity': 'Выберите город',
    'city': 'Город',
    'yourLocation': 'Вы находитесь',
    'cashback': 'кешбек',
    'cashbackTooltip': 'Это твой кешбек! Ты можешь воспользоваться им при оформлении заказа',
    'makeOrder': 'Оформить',
    'catalog': 'Каталог товаров',
    'popularTitle': 'Популярные модели',
    'new': 'Новинка',
    'topSales': 'Топ продаж',
    'search': 'Поиск',
    'clear': 'Очистить',
    'searchHistory': 'История поиска',
    'popularCategories': 'Популярные категории',
    'maybeYouSearched': 'Возможно вы искали',
    'You might like it': 'Вам может понравиться',
    'Popular products': 'Популярные товары',
    'Show all products': 'Показать все товары',

    'subscribedEarlyTitle': 'А ТИ ВЖЕ ПІДПИСАНИЙ.',
    'subscribedEarlyText': 'Готуйся до крутих знижок і акцій!',
    'subscribedNowTitle': 'ДЯКУЄМО ЗА ПІДПИСКУ!',
    'subscribedNowText': 'Перевірте свою скриньку {email} і підтвердіть email',

    'Weekdays': 'Будни',
    'Weekend': 'Выходные',

    'profile': 'Профиль',
    'orders': 'Заказы',
    'addresses': 'Адреса',
    'wishlist': 'Избранное',
    'viewed': 'Просмотренное',
    'logout': 'Выйти',

    'News from blog': 'Новости из блога',
    'All news': 'Все новости',

    'menu': 'Меню',
    'topCategories': 'Топ категории',
    'forBuyer': 'Покупателю',
    'profitably': 'Выгодно',
    'company': 'Компания',
    'contacts': 'Контакты',
    'privacy': 'Политика конфиденциальности',
    'publicOffer': 'Публичная оферта',
    'awards': 'Retail Awards награда за «Лучший сервис»',

    'detail': 'Детальнее',
    'collapse': 'Свернуть',

    'Subscribe to the newsletter': 'Подписаться на рассылку',
    'and withdraw 150 UAH for your first purchase': 'и получи 150 грн на первую покупку',
    'Subscribe': 'Подписаться',

    // корзина
    "BASKET_TOOLTIP": "Сейчас в твоей корзине пусто :(",
    "PERSONAL": "Персональный раздел",
    "TITLE": "В вашей корзине",
    "EXPAND": "Раскрыть",
    "COLLAPSE": "Скрыть",
    "CART": "Корзина",
    "TOTAL_PRICE": "На сумму",
    "TOTAL": "Итого",
    "ORDER_MIN_SUM": "Минимальная сумма заказа #MINPRICE_VALUE# ₴. Добавьте еще пару товаров в корзину",
    "YOUR_CART": "Вы добавили в корзину",
    "READY": "Готовые к покупке товары",
    "DELAY": "Отложенные товары",
    "NOTAVAIL": "Недоступные товары",
    "SUBSCRIBE": "Подписанные товары",
    "SUM": "на сумму",
    "DELETE": "Удалить",
    "2ORDER": "Оформить заказ",
    "RETURN_TO_BUY": "Вернуться к покупкам",
    "GUARANTEE": "Гарантия",
    "CODE": "Код",
    "ADDITIONALLY_BASKET": "Еще в корзине",
    "SPECIFY": "уточняйте",
    "BUY_WITH": "К этому товару подойдут",
    "GIFT": "Подарок",
    "FIRST_PROMO_CODE_BUTTON": "У меня есть промокод",
    "PROMO_CODE_BUTTON": "У меня есть другой промокод",
    "PROMO_CODE": "Промокод",
    "PROMO_CODE_APPLY": "Применить",
    "PROMO_STATUS_BAD": "Промокод не применен",
    "PROMO_STATUS_ENTERED": "Промокод добавлен",
    "PROMO_STATUS_APPLYED": "Промокод применен",
    "PROMO_STATUS_NOT_APPLYED": "Промокод не применен",
    "ADD_PRODUCTS_TITLE": "С этим товаром рекомендуем",
    "ADD_PRODUCTS_SUBTITLE": "Аксессуары",
    "NO_APPLYED_PROMOCODE": "Код #CODE# не сработал. Скидка на этот товар не распространяется, либо срок действия кода истек.",
    "APPLYED_PROMOCODE": "Код #CODE# сработал!",
    "PROMOCODE_PRICE": "Скидка составляет: #PRICE#",
    "CREDIT_ORDER": "Купить в рассрочку",
    "WARRANTY_REQUIRED": "Гарантия обязательна при покупке в кредит/рассрочку",
    "YET": "еще",
    
    // авторизация (старая)
    'AUTH_LOGIN_REGISTER': 'Регистрация',
    'AUTH_LOGIN_AUTH': 'Авторизация',
    'AUTH_LOGIN_BUTTON': 'Вход',
    'AUTH_LOGIN_DESCRIPTION': 'Телефон',
    'AUTH_USER_DATA_ERROR': 'Данные введены некорректно',
    'AUTH_USER_PHONE_DESCRIPTION': 'Для входа по номеру телефона введите его в формате: 38XXXXXXXXXX',
    'REGISTER_USER_PHONE_ERROR': 'Введите телефон в формате: 38XXXXXXXXXX',
    'AUTH_NEXT_BUTTON': 'Далее',
    'AUTH_REGISTER': 'Зарегистрироваться',
    'AUTH_PASSWORD': 'Пароль',
    'AUTH_NAME': 'Имя',
    'AUTH_LAST_NAME': 'Фамилия',
    'AUTH_PHONE': 'Телефон',
    'AUTH_EMAIL': 'E-mail',
    'AUTH_AUTHORIZATION': 'Войти',
    'AUTH_INSERT_SMS_CODE': 'Введите код из SMS или Viber',
    'AUTH_TIMER_TITLE': 'Отправить еще раз через',
    'AUTH_SMS_CODE_WRONG': 'Код введен неверно. Проверьте правильность ввода.',
    'AUTH_SMS_CODE_NOT_DELIVERED': 'Не приходит смс?',
    'AUTH_SMS_CODE_RESEND': 'Отправить еще раз',
    'AUTH_RESTORE_PASSWORD': 'Восстановить пароль',
    'AUTH_RESTORE': 'Восстановить',
    'AUTH_RESTORE_TITLE': 'Восстановление пароля',
    'AUTH_SUCCESFULL': 'Авторизация прошла успешно!',
    'AUTH_SUCCESFULL_REGISTERED': 'Вы были успешно зарегистрированы!',
    'AUTH_RESTORE_SENDED_ON': 'Ссылка на восстановление пароля отправлена на',
    'AUTH_RESTORE_EMAIL_HINT': 'Если ссылка не пришла проверьте папку спам или повторите попытку',
    'AUTH_RESTORE_CONTROL_CODE': 'Контрольное слово',
    'AUTH_RESTORE_SUCCESSFULL': 'Ваш пароль успешно изменен!',
    'AUTH_SYSTEM_ERROR': 'Системная ошибка, повторите позже.',
    'AUTH_AUTH_TITLE': 'Персональный раздел',
    'AUTH_NO_AUTH_TITLE': 'Вход / Регистрация',
    'AUTH_CHANGE_EMAIL': 'Ввести другой E-mail или телефон',
    'AUTH_CHANGE_PHONE': 'Ввести другой номер телефона',
    'AUTH_ADD_FAVORITES': 'Для добавления в избранное вам необходимо авторизоваться',
    'ENTER_PERSONAL': 'Войти',
    'AUTH_OK': 'Ты зарегистрирован. А покупку уже совершил?',
    'BONUS_SUM_TOOLTIP': 'Это твой кешбек! Ты можешь воспользоваться им при оформлении заказа',
  },
  'ua': {
    'Loading...': 'Завантаження...',
    'citySearch': 'Пошук міста',
    'choiceCity': 'Виберіть місто',
    'city': 'Місто',
    'yourLocation': 'Ви знаходитесь',
    'cashback': 'кешбек',
    'cashbackTooltip': 'Це твій кешбек! Ти можеш скористатися ним при оформленні замовлення',
    'makeOrder': 'Оформити',
    'catalog': 'Каталог товарів',
    'popularTitle': 'Популярні моделі',
    'new': 'Новинка',
    'topSales': 'Топ продажів',
    'search': 'Пошук',
    'clear': 'Очистити',
    'searchHistory': 'Історія пошуку',
    'popularCategories': 'Популярні категорії',
    'maybeYouSearched': 'Можливо ви шукали',
    'You might like it': 'Вам може сподобатись',
    'Popular products': 'Популярні товари',
    'Show all products': 'Показати всі товари',

    'subscribedEarlyTitle': 'А ТИ ВЖЕ ПІДПИСАНИЙ.',
    'subscribedEarlyText': 'Готуйся до крутих знижок і акцій!',
    'subscribedNowTitle': 'ДЯКУЄМО ЗА ПІДПИСКУ!',
    'subscribedNowText': 'Перевірте свою скриньку {email} і підтвердіть email',

    'Weekdays': 'Будні',
    'Weekend': 'Вихідні',

    'profile': 'Профіль',
    'orders': 'Замовлення',
    'addresses': 'Адреси',
    'wishlist': 'Обране',
    'viewed': 'Переглянуте',
    'logout': 'Вийти',

    'News from blog': 'Новини з блогу',
    'All news': 'Всі новини',

    'menu': 'Меню',
    'topCategories': 'Топ категорії',
    'forBuyer': 'Покупцеві',
    'profitably': 'Вигідно',
    'company': 'Компанія',
    'contacts': 'Контакти',
    'privacy': 'Політика конфіденційності',
    'publicOffer': 'Публічна оферта',
    'awards': 'Retail Awards нагорода за «Кращий сервiс»',

    'detail': 'Детальніше',
    'collapse': 'Згорнути',

    'Subscribe to the newsletter': 'Підпишись на розсилку',
    'and withdraw 150 UAH for your first purchase': 'і отримай 150 грн на першу покупку',
    'Subscribe': 'Підписатись',

    // корзина
    "CART": "Кошик",
    "BASKET_TOOLTIP": "Наразі в твоєму кошику порожньо :(",
    "PERSONAL": "Персональний розділ",
    "TITLE": "У вашому кошику",
    "EXPAND": "Розкрити",
    "COLLAPSE": "Приховати",
    "TOTAL_PRICE": "На суму",
    "TOTAL": "Разом",
    "ORDER_MIN_SUM": "Мінімальна сума замовлення #MINPRICE_VALUE# ₴. Додайте ще кілька товарів до кошика",
    "YOUR_CART": "Ви додали в кошик",
    "READY": "Готові до покупки товари",
    "DELAY": "Відкладені товари",
    "NOTAVAIL": "Недоступні товари",
    "SUBSCRIBE": "Підписані товари",
    "SUM": "на суму",
    "DELETE": "Видалити",
    "2ORDER": "Оформити замовлення",
    "RETURN_TO_BUY": "Повернутися до покупок",
    "GUARANTEE": "Гарантія",
    "CODE": "Код",
    "ADDITIONALLY_BASKET": "Ще в кошику",
    "SPECIFY": "уточнюйте",
    "BUY_WITH": "До цього товару підійдуть",
    "GIFT": "Подарунок",
    "FIRST_PROMO_CODE_BUTTON": "У мене є промокод",
    "PROMO_CODE_BUTTON": "У мене є інший промокод",
    "PROMO_CODE": "Промокод",
    "PROMO_CODE_APPLY": "Застосувати",
    "PROMO_STATUS_BAD": "Промокод застосований",
    "PROMO_STATUS_ENTERED": "Промокод доданий",
    "PROMO_STATUS_APPLYED": "Промокод застосований",
    "PROMO_STATUS_NOT_APPLYED": "Промокод застосований",
    "ADD_PRODUCTS_TITLE": "З цим товаром рекомендуємо",
    "ADD_PRODUCTS_SUBTITLE": "Аксесуари",
    "NO_APPLYED_PROMOCODE": "Код #CODE# не спрацював. Знижка на цей товар не поширюється, або термін дії коду закінчився.",
    "APPLYED_PROMOCODE": "Код #CODE# спрацював!",
    "PROMOCODE_PRICE": "Знижка становить: #PRICE#",
    "CREDIT_ORDER": "Купити в розстрочку",
    "WARRANTY_REQUIRED": "Гарантія обов’язкова при купівлі у розстрочку",
    "YET": "ще",

    // авторизация (старая)
    "AUTH_LOGIN_REGISTER": "Реєстрація",
    "AUTH_LOGIN_AUTH": "Авторизація",
    "AUTH_LOGIN_BUTTON": "Вхід",
    "AUTH_LOGIN_DESCRIPTION": "Телефон",
    "AUTH_USER_DATA_ERROR": "Дані введені некоректно",
    "AUTH_USER_PHONE_DESCRIPTION": "Для входу за номером телефону введіть його в форматі: 38XXXXXXXXXX",
    "REGISTER_USER_PHONE_ERROR": "Введіть телефон в форматі: 38XXXXXXXXXX",
    "AUTH_NEXT_BUTTON": "Далі",
    "AUTH_REGISTER": "Зареєструватися",
    "AUTH_PASSWORD": "Пароль",
    "AUTH_NAME": "Ім'я",
    "AUTH_LAST_NAME": "Прізвище",
    "AUTH_PHONE": "Телефон",
    "AUTH_EMAIL": "E-mail",
    "AUTH_AUTHORIZATION": "Увійти",
    "AUTH_INSERT_SMS_CODE": "Введіть код з SMS або Viber",
    "AUTH_TIMER_TITLE": "Відправити ще раз через",
    "AUTH_SMS_CODE_WRONG": "Код введено невірно. Перевірте правильність введення.",
    "AUTH_SMS_CODE_NOT_DELIVERED": "Не приходить смс?",
    "AUTH_SMS_CODE_RESEND": "Відправити ще раз",
    "AUTH_RESTORE_PASSWORD": "Відновити пароль",
    "AUTH_RESTORE": "Відновити",
    "AUTH_RESTORE_TITLE": "Відновлення паролю",
    "AUTH_SUCCESFULL": "Авторизація пройшла успішно!",
    "AUTH_SUCCESFULL_REGISTERED": "Ви були успішно зареєстровані!",
    "AUTH_RESTORE_SENDED_ON": "Посилання на відновлення пароля відправлено на",
    "AUTH_RESTORE_EMAIL_HINT": "Якщо посилання не прийшло перевірте папку спам або спробуйте ще раз",
    "AUTH_RESTORE_CONTROL_CODE": "Контрольне слово",
    "AUTH_RESTORE_SUCCESSFULL": "Ваш пароль успішно змінений!",
    "AUTH_SYSTEM_ERROR": "Системна помилка, повторіть пізніше.",
    "AUTH_AUTH_TITLE": "Персональний розділ",
    "AUTH_NO_AUTH_TITLE": "Вхід / Реєстрація",
    "AUTH_CHANGE_EMAIL": "Ввести інший E-mail або телефон",
    "AUTH_CHANGE_PHONE": "Ввести інший номер телефону",
    "AUTH_ADD_FAVORITES": "Щоб додати до обраного вам необхідно авторизуватись",
    "ENTER_PERSONAL": "Увійти",
    "AUTH_OK": "Ти зареєстрований. А покупку вже зробив?",
    "BONUS_SUM_TOOLTIP": "Це твій кешбек! Ти можеш скористатися ним при оформленні замовлення",
  }
}

export default messages